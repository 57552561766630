import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Collapse,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarToggler,
  Table,
} from 'reactstrap'
import IAuthState, { isL0, isL1, isL4, IUser } from '../../model/IAuthState'
import IRipplesState from '../../model/IRipplesState'
import { setCampaign, setServicesAvailable, setUser } from '../../redux/ripples.actions'
import { fetchUsers, getCurrentUser } from '../../services/UserUtils'
import { createDomain, deleteDomain, fetchDomainNames, updateDomain } from '../../services/DomainUtils'
import TopNavLinks from '../../components/TopNavLinks'
import Login from '../../components/Login'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import ZerotierService from '../../services/ZerotierUtils'
import { createApiKey, fetchApiKeys, removeApiKey } from '../../services/ApiKeyUtils'
import DateService from '../../services/DateUtils'
import 'react-datepicker/dist/react-datepicker.css'
import SoiService from '../../services/SoiUtils'
import LocalStorageService from '../../services/LocalStorageService'
import { IServicesDefinition } from '../../model/IService'
import {
  fetchServicesDefinitionByCampaign,
  isChatServiceAvailable,
  isIntrusionServiceAvailable,
  isMyMapsServiceAvailable,
} from '../../services/ServicesUtils'
import ICampaign from '../../model/ICampaign'
import { fetchCampaignByName, fetchCampaignNames } from '../../services/CampaignUtils'
const { NotificationManager } = require('react-notifications')

interface StateType {
  activePanel: string
  isNavOpen: boolean
  settingInputElem: any | null
  settingInputValue: string
  isSettingModalOpen: boolean
  isNewSettingModalOpen: boolean
  isParamConfirmModalOpen: boolean
  isLeftPanelOpen: boolean
  isAdminOptionsOpen: boolean
  isConfirmModalOpen: boolean
  settingId: string
  settingDomainName: string
  settingParamName: string
  settingNewInputTitle: string
  settingNewInputValue: string
  settingNewInputDomain: string
  domains: string[]
  campaignsAvailable: string[]
  nodeId: string
  isZtSelectorOpen: boolean
  ztCmd: string
  isRemoveDomainModalOpen: boolean
  isRemovePlanModalOpen: boolean
  plansID: string[]
  planToDelete: string
  domainToRemove: any | null
  isNewTokenVisible: boolean
  isNewApiKeyVisible: boolean
  isRemoveTokenModalOpen: boolean
  isRemoveApiKeyModalOpen: boolean
  newApiKeyCampaignSelected: string
  apiKeys: IApiKeys[]
  isNewTokenModalOpen: boolean
  isNewApiKeyModalOpen: boolean
  tokenGenerated: string
  tokenToRemove: string
  tokenEmailInput: string
  permissions: string[]
  domainInputElem: any | null
  domainInputValue: string
  domainPreviousValue: string
  domainNewInput: string
  domainNewInputVisible: boolean
  preferenceSymbol: string
  preferenceMeasurementUnit: string
  preferenceCoordinatesFormat: string
  users: {
    name: string
    email: string
  }[]
}

interface PropsType {
  setUser: (user: IUser) => any
  setCampaign: (_: ICampaign | null) => void
  setServicesAvailable: (_: IServicesDefinition[]) => void
  auth: IAuthState
  isDarkMode: boolean
  servicesAvailable: IServicesDefinition[]
  campaignSelected: ICampaign | null
}

interface IApiKeys {
  token: string
  email: string
  domain: string[]
  permission: string[]
  expirationDate: number
}

interface ISettingsPanelOptions {
  icon: string
  label: string
  action: () => void
  path: string | undefined
  visible: boolean
}

export class SettingsPanel extends Component<PropsType, StateType> {
  public notificationSystem: any = null
  public timerID: number = 0
  private ztService: ZerotierService = new ZerotierService()
  private soiService: SoiService = new SoiService()
  private localStorageService: LocalStorageService = new LocalStorageService()

  constructor(props: any) {
    super(props)
    this.state = {
      activePanel: 'default', // Set a default panel
      isNavOpen: false,
      settingInputElem: null,
      settingInputValue: '',
      isSettingModalOpen: false,
      isNewSettingModalOpen: false,
      isConfirmModalOpen: false,
      isParamConfirmModalOpen: false,
      isLeftPanelOpen: true,
      isAdminOptionsOpen: false,
      settingId: '',
      settingDomainName: '',
      settingParamName: '',
      settingNewInputTitle: '',
      settingNewInputValue: '',
      settingNewInputDomain: '',
      domains: [],
      campaignsAvailable: [],
      nodeId: '',
      isZtSelectorOpen: false,
      ztCmd: '',
      isRemoveDomainModalOpen: false,
      isRemovePlanModalOpen: false,
      plansID: [],
      planToDelete: '',
      domainToRemove: null,
      isNewTokenVisible: false,
      isNewApiKeyVisible: false,
      newApiKeyCampaignSelected: '',
      apiKeys: [],
      isNewTokenModalOpen: false,
      isNewApiKeyModalOpen: false,
      isRemoveTokenModalOpen: false,
      isRemoveApiKeyModalOpen: false,
      tokenGenerated: '',
      tokenToRemove: '',
      tokenEmailInput: '',
      permissions: ['read', 'write'],
      domainInputElem: null,
      domainInputValue: '',
      domainPreviousValue: '',
      domainNewInput: '',
      domainNewInputVisible: false,
      preferenceSymbol: 'normal',
      preferenceMeasurementUnit: 'metric',
      preferenceCoordinatesFormat: 'DD',
      users: [],
    }
    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this)
    this.fetchApiKeys = this.fetchApiKeys.bind(this)
    this.toogleNewTokenModal = this.toogleNewTokenModal.bind(this)
    this.toogleNewApiKeyModal = this.toogleNewApiKeyModal.bind(this)
    this.generateToken = this.generateToken.bind(this)
    this.generateApiKey = this.generateApiKey.bind(this)
    this.removeToken = this.removeToken.bind(this)
    this.toggleZtSelector = this.toggleZtSelector.bind(this)
    this.onNodeIdSubmission = this.onNodeIdSubmission.bind(this)
    this.redirectToChatPage = this.redirectToChatPage.bind(this)
    this.redirectToUserProfilePage = this.redirectToUserProfilePage.bind(this)
    this.toogleRemovePlanModal = this.toogleRemovePlanModal.bind(this)
    this.handleChangePreferences = this.handleChangePreferences.bind(this)
    this.handleChangePlan = this.handleChangePlan.bind(this)
    this.removePlan = this.removePlan.bind(this)
  }

  public async loadCurrentlyLoggedInUser() {
    try {
      const user: IUser = await getCurrentUser()
      this.props.setUser(user)
    } catch (error) {
      localStorage.removeItem('ACCESS_TOKEN')
    }
  }

  private async getDomains() {
    const domains: string[] = await fetchDomainNames()

    this.setState({
      domains,
    })
  }

  private async getCampaignsAvailable() {
    const campaignsAvailable: string[] = await fetchCampaignNames(this.props.auth.currentUser.email)
    this.setState({ campaignsAvailable })
  }

  private async getPlansID() {
    const plansID: string[] = await this.soiService.listPlans()
    this.setState({ plansID })
  }

  private fetchLocalStorage() {
    this.setState({
      preferenceSymbol: this.localStorageService.getSymbolsType(),
      preferenceMeasurementUnit: this.localStorageService.getMeasurementUnit(),
      preferenceCoordinatesFormat: this.localStorageService.getCoordinatesFormat(),
    })
  }

  private async getUsers() {
    fetchUsers(this.props.auth.currentUser.role)
      .then((data) => {
        const users = data.map((m: any) =>
          Object.assign({}, m, {
            name: m.name,
            email: m.email,
          })
        )
        this.setState({ users })
      })
      .catch((_) => {
        NotificationManager.warning('Failed to fetch users')
      })
  }

  private async fetchCampaignAndServicesAvailable() {
    // Fetch campaign selected
    const campaignSelected: ICampaign = await fetchCampaignByName(
      this.localStorageService.getCampaignSelected(),
      this.props.auth.currentUser.email
    )
    if (this.localStorageService.getCampaignSelected() !== campaignSelected.name) {
      this.localStorageService.setCampaignSelected(campaignSelected.name)
    }
    this.props.setCampaign(campaignSelected)

    // Fetch services available
    const servicesAvailable: IServicesDefinition[] = await fetchServicesDefinitionByCampaign(
      this.localStorageService.getCampaignSelected()
    )
    this.props.setServicesAvailable(servicesAvailable)
  }

  public async componentDidMount() {
    await this.loadCurrentlyLoggedInUser()
    if (!(this.props.auth.authenticated && (isL0(this.props.auth) || isL1(this.props.auth)))) {
      // NotificationManager.error('Only available for administrators')
    } else {
      this.getDomains()
      this.getCampaignsAvailable()
      this.getPlansID()
    }
    if (this.props.auth.authenticated && !isL4(this.props.auth)) {
      this.getDomains()
      this.getCampaignsAvailable()
      this.fetchApiKeys()
    }
    if (this.props.auth.authenticated && (isL0(this.props.auth) || isL1(this.props.auth))) {
      this.getUsers()
    }

    // check LocalStorage
    this.fetchLocalStorage()

    // Fetch campaign and services available
    this.fetchCampaignAndServicesAvailable()
  }

  public componentWillUnmount() {
    clearInterval(this.timerID)
  }

  public onNavToggle() {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  public render() {
    const settingsOptions: ISettingsPanelOptions[] = [
      {
        icon: '️⚠️',
        label: 'SOI Risk Analysis',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/risk',
        visible: true,
      },
      {
        icon: '📃',
        label: 'Text Messages',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/messages/text',
        visible: true,
      },
      {
        icon: '🛡',
        label: 'Intrusion Areas',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/intrusion',
        visible: isIntrusionServiceAvailable(this.props.servicesAvailable),
      },
      {
        icon: '💬',
        label: 'Chat Selector',
        action: () => this.setState({ activePanel: 'chatSelector' }),
        path: undefined,
        visible: isChatServiceAvailable(this.props.servicesAvailable),
      },
      {
        icon: '🗺',
        label: 'KML Manager',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/kml/manager',
        visible: isMyMapsServiceAvailable(this.props.servicesAvailable),
      },
      {
        icon: '🔑',
        label: 'Generate API key',
        action: () => this.setState({ activePanel: 'apiKeyByDomainEditor' }),
        path: undefined,
        visible: true,
      },
      {
        icon: '🔑',
        label: 'Campaign - API key',
        action: () => this.setState({ activePanel: 'apiKeyEditor' }),
        path: undefined,
        visible: true,
      },
      {
        icon: '💾',
        label: 'Local Preferences',
        action: () => this.setState({ activePanel: 'preferencesEditor' }),
        path: undefined,
        visible: true,
      },
      {
        icon: '️🕸️',
        label: 'Zerotier',
        action: () => this.setState({ activePanel: 'zerotierEditor' }),
        path: undefined,
        visible: true,
      },
      {
        icon: '️🗃️',
        label: 'Campaign Manager',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/campaign',
        visible: true,
      },
    ]

    const adminOptions: ISettingsPanelOptions[] = [
      {
        icon: '👥',
        label: 'Users Manager',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/user/manager',
        visible: true,
      },
      {
        icon: '🌐',
        label: 'Domain Editor',
        action: () => this.setState({ activePanel: 'domainEditor' }),
        path: undefined,
        visible: true,
      },
      {
        icon: '🗑️',
        label: 'Plan Manager',
        action: () => this.setState({ activePanel: 'planManagerEditor' }),
        path: undefined,
        visible: true,
      },
      {
        icon: '🔧',
        label: 'Services Available',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/services',
        visible: true,
      },
      {
        icon: '⚙️',
        label: 'Application Settings',
        action: () => this.setState({ activePanel: 'default' }),
        path: '/settings/manager',
        visible: true,
      },
    ]

    return (
      <>
        <Navbar id={this.props.isDarkMode ? 'navbar-darkmode' : ''} color="faded" light={true} expand="md">
          <NavbarToggler className="mr-2" onClick={() => this.setState({ isNavOpen: !this.state.isNavOpen })} />
          <Collapse isOpen={this.state.isNavOpen} navbar={true}>
            <TopNavLinks />
            <Nav className="ml-auto" navbar={true}>
              {this.props.auth.authenticated && this.buildUserProfilePage()}
              {this.props.auth.authenticated && !isL4(this.props.auth) && this.buildZerotierSelector()}

              <Login />
            </Nav>
          </Collapse>
        </Navbar>

        <div className={this.props.isDarkMode ? 'settings-panel-content darkmode' : 'settings-panel-content'}>
          {/* Wrapper for Left and Right Panels */}
          <div className="settings-panel-wrapper">
            {/* Left Side Panel */}
            <div className={`settings-panel-leftSide ${this.state.isLeftPanelOpen ? 'open' : 'collapsed'}`}>
              {/* Collapse Toggle Button */}
              <button
                className="collapse-btn"
                onClick={() => this.setState({ isLeftPanelOpen: !this.state.isLeftPanelOpen })}
              >
                {this.state.isLeftPanelOpen ? '⬅️' : '➡️'}
              </button>

              {this.props.auth.authenticated && !isL4(this.props.auth) && (
                <>
                  {/* Settings Options */}
                  <div className="settings-options-container">
                    {settingsOptions
                      .filter((option) => option.visible)
                      .map(({ icon, label, action, path }, index) => {
                        const content = (
                          <div className="setting-panel-label" onClick={() => action()}>
                            <span role="img" aria-label="optionIcon">
                              {icon}
                            </span>
                            {this.state.isLeftPanelOpen && <span className="label-text">{label}</span>}
                          </div>
                        )
                        return typeof path === 'string' ? (
                          <Link key={`link_${index}`} to={path} className="setting-panel-link">
                            {content}
                          </Link>
                        ) : (
                          <div key={`label_${index}`} className="non-link-wrapper">
                            {content}
                          </div>
                        )
                      })}
                  </div>

                  {/* Admin Toggle Button */}
                  <button
                    className={`admin-toggle-btn ${this.state.isLeftPanelOpen ? '' : 'collapsed'}`}
                    onClick={() => this.setState({ isAdminOptionsOpen: !this.state.isAdminOptionsOpen })}
                  >
                    {this.state.isLeftPanelOpen ? (
                      this.state.isAdminOptionsOpen ? (
                        'Hide Admin Panel'
                      ) : (
                        'Show Admin Panel'
                      )
                    ) : (
                      <span role="img" aria-label="adminToggleIcon">
                        ↕️
                      </span>
                    )}
                  </button>

                  {/* Admin Options */}
                  {this.state.isAdminOptionsOpen && (
                    <div className="admin-options-container">
                      {adminOptions
                        .filter((option) => option.visible)
                        .map(({ icon, label, action, path }, index) => {
                          const content = (
                            <div className="setting-panel-label" onClick={() => action()}>
                              <span role="img" aria-label="adminOptionIcon">
                                {icon}
                              </span>
                              {this.state.isLeftPanelOpen && <span className="label-text">{label}</span>}
                            </div>
                          )
                          return typeof path === 'string' ? (
                            <Link key={`link_${index}`} to={path} className="setting-panel-link">
                              {content}
                            </Link>
                          ) : (
                            <div key={`label_${index}`} className="non-link-wrapper">
                              {content}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Right Side Panel */}
            <div className={`settings-panel-rightSide ${this.state.isLeftPanelOpen ? 'expanded' : 'full-width'}`}>
              {this.state.activePanel === 'chatSelector' && this.renderChatSelector()}
              {this.state.activePanel === 'apiKeyByDomainEditor' && this.renderApiKeyByDomainEditor()}
              {this.state.activePanel === 'apiKeyEditor' && this.renderApiKeyEditor()}
              {this.state.activePanel === 'preferencesEditor' && this.renderPreferencesEditor()}
              {this.state.activePanel === 'domainEditor' && this.renderDomainEditor()}
              {this.state.activePanel === 'planManagerEditor' && this.renderPlanManagerEditor()}
              {this.state.activePanel === 'zerotierEditor' && this.renderZerotier()}

              {/* Default Message */}
              {this.state.activePanel === 'default' && (
                <>
                  <h4>Welcome to Settings</h4>
                  <p>Select an option from the left panel.</p>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  public renderZerotier() {
    return (
      <div className="zerotier-network-container">
        <h4>Zerotier Network</h4>

        {/* Node Address Input */}
        <InputGroup className="zt-input-group">
          <Input
            name="node_address"
            placeholder="Node address"
            onChange={(evt) => this.setState({ nodeId: evt.target.value })}
            value={this.state.nodeId}
            type="text"
            required={true}
          />
        </InputGroup>

        {/* Add Node Button */}
        <div className="btn-zt-modal">
          <Button color="primary" onClick={this.onNodeIdSubmission}>
            Add Node
          </Button>
        </div>
      </div>
    )
  }

  public buildZerotierSelector() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isZtSelectorOpen}
        toggle={this.toggleZtSelector}
      >
        <ModalHeader toggle={this.toggleZtSelector}> Zerotier Network </ModalHeader>
        <ModalBody>
          <InputGroup>
            <Input
              name="node_address"
              placeholder="Node address"
              onChange={(evt) => this.setState({ nodeId: evt.target.value })}
              value={this.state.nodeId}
              type="text"
              required={true}
            />
          </InputGroup>
          <div className="btn-zt-modal">
            <Button color="primary" onClick={this.onNodeIdSubmission}>
              Add node
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  public async onNodeIdSubmission() {
    const { nodeId } = this.state
    if (nodeId === '' || nodeId.length !== 10) {
      NotificationManager.error('Please insert a valid 10-digit ZeroTier node ID!')
      return
    }
    const { status, message } = await this.ztService.joinNetwork(nodeId)
    if (status === 'Success') {
      NotificationManager.success('Node added successfully to the Ripples Zerotier network!')
      this.setState({ ztCmd: message, isZtSelectorOpen: !this.state.isZtSelectorOpen })
    } else {
      NotificationManager.error(message)
    }
    this.setState({ nodeId: '' })
  }

  public toggleZtSelector() {
    this.setState({ isZtSelectorOpen: !this.state.isZtSelectorOpen })
  }

  public async fetchApiKeys() {
    let email = this.props.auth.currentUser.email
    if (isL0(this.props.auth) || isL1(this.props.auth)) {
      email = 'all'
    }
    const apiKeys: any = await fetchApiKeys(email)
    this.setState({ apiKeys })
  }

  private renderApiKeyByDomainEditor() {
    return (
      <>
        <div className="api-key-manager-container">
          <h4>API Keys</h4>

          <Table id="token-table" responsive={true} striped={true}>
            <thead>
              <tr>
                <th>Expiration date</th>
                <th>API key</th>
                <th>Campaigns</th>
                <th>Permission</th>
                <th>User</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {this.state.apiKeys.map((apiKey, index) => (
                <tr key={index}>
                  <td>{DateService.timestampToReadableDateOnly(apiKey.expirationDate)}</td>
                  <td>
                    {apiKey.token}
                    <CopyToClipboard text={apiKey.token} onCopy={() => this.onApiKeyCopy()}>
                      <i className="fas fa-copy fa-lg" title="Copy key" />
                    </CopyToClipboard>
                  </td>
                  <td>
                    {apiKey.domain.map((campaignName, indexCampaign) => (
                      <ul key={`optCampaign-${campaignName}-${indexCampaign}`}>
                        <li>{campaignName}</li>
                      </ul>
                    ))}
                  </td>
                  <td>
                    {this.state.permissions.map((p, indexPermission) => (
                      <label key={indexPermission}>
                        <input
                          type="checkbox"
                          className={`optPermission-${indexPermission}`}
                          value={p}
                          checked={apiKey.permission.includes(p)}
                          disabled={true}
                        />
                        {p}
                      </label>
                    ))}
                  </td>
                  <td>{apiKey.email}</td>
                  <td>
                    {this.props.auth.authenticated && (
                      <i
                        className="fas fa-trash"
                        title="Remove API Key"
                        onClick={() => this.toogleRemoveApiKeyModal(apiKey.token)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {this.state.isNewApiKeyVisible ? (
            <>
              <hr />
              <h5 className="token-title">API Key Settings:</h5>

              {/* Email Input */}
              <div className="token-email-input">
                <span>Email:</span>
                <select
                  value={this.state.tokenEmailInput}
                  onChange={(event) => this.setState({ tokenEmailInput: event.target.value })}
                >
                  <option disabled={true} hidden={true} value="" />
                  {this.state.users.map((user, index) => (
                    <option value={user.email} key={index}>
                      {`${user.name} [${user.email}]`}
                    </option>
                  ))}
                </select>
              </div>

              {/* Campaign Selection */}
              <div className="token-domain-input">
                <span>Campaigns:</span>
                {this.state.campaignsAvailable.map((campaignName, indexCampaign) => (
                  <label key={indexCampaign}>
                    <input
                      type="radio"
                      className="new-token-domain"
                      value={campaignName}
                      checked={this.state.newApiKeyCampaignSelected === campaignName}
                      onChange={(event) => this.setState({ newApiKeyCampaignSelected: event.target.value })}
                    />
                    {campaignName}
                  </label>
                ))}
              </div>

              {/* Permissions */}
              <div className="token-permission-input">
                <span>Permissions:</span>
                {this.state.permissions.map((p, indexPermission) => (
                  <label key={indexPermission}>
                    <input type="checkbox" className="new-token-permission" value={p} />
                    {p}
                  </label>
                ))}
              </div>

              {/* Action Buttons */}
              <Button color="success" className="new-token-btn" onClick={() => this.generateApiKey()}>
                Generate Key
              </Button>
              <Button
                color="secondary"
                className="new-token-btn"
                onClick={() => this.setState({ isNewApiKeyVisible: false })}
              >
                Cancel
              </Button>
            </>
          ) : (
            (isL0(this.props.auth) || isL1(this.props.auth)) && (
              <Button color="secondary" onClick={() => this.setState({ isNewApiKeyVisible: true })}>
                Generate API Key
              </Button>
            )
          )}
        </div>

        {/* Modal */}
        {this.buildNewTokenModal()}
        {this.buildRemoveTokenModal()}
        {this.buildRemoveApiKeyModal()}
      </>
    )
  }

  private renderApiKeyEditor() {
    return (
      <>
        <div className="api-key-manager-container">
          <h4>Campaign - API Keys</h4>

          <Table id="token-table" responsive={true} striped={true}>
            <thead>
              <tr>
                <th>Expiration date</th>
                <th>API key</th>
                <th>Campaigns</th>
                <th>Permission</th>
                <th>User</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {this.state.apiKeys.map((apiKey, index) => (
                <tr key={index}>
                  <td>{DateService.timestampToReadableDateOnly(apiKey.expirationDate)}</td>
                  <td>
                    {apiKey.token}
                    <CopyToClipboard text={apiKey.token} onCopy={this.onApiKeyCopy}>
                      <i className="fas fa-copy fa-lg" title="Copy key" />
                    </CopyToClipboard>
                  </td>

                  <td>
                    {apiKey.domain.map((campaignName, indexCampaign) => (
                      <ul key={`optCampaign-${campaignName}-${indexCampaign}`}>
                        <li>{campaignName}</li>
                      </ul>
                    ))}
                  </td>

                  <td>
                    {this.state.permissions.map((p, indexPermission) => (
                      <label key={indexPermission}>
                        <input
                          type="checkbox"
                          className={`optPermission-${indexPermission}`}
                          value={p}
                          checked={apiKey.permission.includes(p)}
                          disabled={true}
                        />
                        {p}
                      </label>
                    ))}
                  </td>

                  <td>{apiKey.email}</td>

                  <td>
                    {this.props.auth.authenticated && (
                      <i
                        className="fas fa-trash"
                        title="Remove API Key"
                        onClick={() => this.toogleRemoveApiKeyModal(apiKey.token)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          {this.state.isNewApiKeyVisible ? (
            <>
              <hr />
              <h5 className="token-title">API Key Settings:</h5>

              <div className="token-email-input">
                <span>Email:</span>
                <select
                  value={this.state.tokenEmailInput}
                  onChange={(event) => this.setState({ tokenEmailInput: event.target.value })}
                >
                  <option disabled={true} hidden={true} value="" />
                  {this.state.users.map((user, index) => (
                    <option value={user.email} key={index}>
                      {`${user.name} [${user.email}]`}
                    </option>
                  ))}
                </select>
              </div>

              <div className="token-domain-input">
                <span>Campaigns:</span>
                {this.state.campaignsAvailable.map((campaignName, indexCampaign) => (
                  <label key={indexCampaign}>
                    <input
                      type="radio"
                      className="new-token-domain"
                      value={campaignName}
                      checked={this.state.newApiKeyCampaignSelected === campaignName}
                      onChange={(event) => this.setState({ newApiKeyCampaignSelected: event.target.value })}
                    />
                    {campaignName}
                  </label>
                ))}
              </div>

              <div className="token-permission-input">
                <span>Permissions:</span>
                {this.state.permissions.map((p, indexPermission) => (
                  <label key={indexPermission}>
                    <input type="checkbox" className="new-token-permission" value={p} />
                    {p}
                  </label>
                ))}
              </div>

              <Button color="success" className="new-token-btn" onClick={this.generateApiKey}>
                Generate Key
              </Button>
              <Button
                color="secondary"
                className="new-token-btn"
                onClick={() => this.setState({ isNewApiKeyVisible: false })}
              >
                Cancel
              </Button>
            </>
          ) : isL0(this.props.auth) || isL1(this.props.auth) ? (
            <Button color="secondary" onClick={() => this.setState({ isNewApiKeyVisible: true })}>
              Generate API Key
            </Button>
          ) : null}
        </div>

        {/* Modal */}
        {this.buildNewApiKeyModal()}
        {this.buildRemoveApiKeyModal()}
      </>
    )
  }

  public renderDomainEditor() {
    return (
      <>
        <div className="domain-editor-container">
          <h4>Edit Domain</h4>

          {/* Existing Domains */}
          {this.state.domains.map((d, index) =>
            this.state.domainInputElem && this.state.domainInputElem.id === 'domain-' + index ? (
              <div key={index} className="domainRow" domain-input={'domain-' + index}>
                <input
                  type="text"
                  className="domain-input"
                  id={'domain-' + index}
                  value={this.state.domainInputValue}
                  onChange={(event) => this.setState({ domainInputValue: event.target.value })}
                  disabled={false}
                />
                <i className="fas fa-check" title="Update domain" onClick={() => this.updateDomainName()} />
              </div>
            ) : (
              <div key={index} className="domainRow" domain-input={'domain-' + index}>
                <input type="text" className="domain-input" id={'domain-' + index} value={d} disabled={true} />
                <i
                  className="fas fa-pencil-alt"
                  title="Edit domain"
                  onClick={(event) => this.enableInputDomain(event)}
                />
                <i
                  className="fas fa-trash"
                  title="Remove domain"
                  onClick={(event) => this.toogleRemoveDomainModal(event)}
                />
              </div>
            )
          )}

          {/* New Domain Input */}
          {this.state.domainNewInputVisible ? (
            <div className="new-domain-container">
              <input
                type="text"
                className="domain-input"
                id="domain-new-input"
                placeholder="Domain name"
                value={this.state.domainNewInput}
                onChange={(event) => this.setState({ domainNewInput: event.target.value })}
              />
              <div className="btn-domain-modal">
                <Button color="success" onClick={() => this.createDomain()}>
                  Add
                </Button>
                <Button color="secondary" onClick={() => this.setState({ domainNewInputVisible: false })}>
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="btn-domain-modal">
              <Button
                color="primary"
                onClick={() => this.setState({ domainNewInputVisible: !this.state.domainNewInputVisible })}
              >
                New Domain
              </Button>
            </div>
          )}
        </div>

        {/* Modal */}
        {this.buildRemoveDomainModal()}
      </>
    )
  }

  private renderChatSelector() {
    const { campaignSelected } = this.props
    return (
      <>
        <div className="chat-selector-container">
          <h4>Choose mission</h4>
          {campaignSelected && (
            <>
              {/* Redirect link */}
              <Link id="chat-link" to="/chat" />

              <div key="chatGroup" className="chatGroup" onClick={() => this.redirectToChatPage(campaignSelected.name)}>
                {campaignSelected.name}
              </div>

              {campaignSelected.missions.map((mission, index) => {
                return (
                  <div
                    key={'chatGroup-' + index}
                    className="chatGroup"
                    onClick={() => this.redirectToChatPage(mission.id)}
                  >
                    {mission.name}
                  </div>
                )
              })}
            </>
          )}
        </div>
      </>
    )
  }

  private renderPlanManagerEditor() {
    return (
      <>
        <div className="plan-manager-container">
          <h4>Plan Manager</h4>

          <select
            className="input-remove-plan"
            title="Select plan to delete"
            value={this.state.planToDelete === '' ? 'Select plan to remove' : this.state.planToDelete}
            onChange={this.handleChangePlan}
          >
            <option value={''}>Select plan to remove</option>
            {this.state.plansID.map((plan, index) => (
              <option value={plan} key={index}>
                {plan}
              </option>
            ))}
          </select>

          {this.state.planToDelete !== '' && (
            <Button color="danger" className="btn-remove-plan" onClick={this.toogleRemovePlanModal}>
              Remove
            </Button>
          )}
        </div>

        {/* Modal */}
        {this.buildRemovePlanModal()}
      </>
    )
  }

  public buildRemoveDomainModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemoveDomainModalOpen}>
        <ModalHeader toggle={() => this.toogleRemoveDomainModal(null)}> Remove domain </ModalHeader>
        <ModalBody>
          <div> The domain will be removed. Do you want to continue?</div>
          <Button color="danger" onClick={() => this.removeDomain()}>
            Yes
          </Button>
        </ModalBody>
      </Modal>
    )
  }

  public async createDomain() {
    const domainName = this.state.domainNewInput

    const response = await createDomain(domainName)
    if (response.status === 'Success') {
      this.setState({
        domainNewInput: '',
        domainNewInputVisible: false,
      })
      this.getDomains()
      NotificationManager.success('Created domain')
    } else {
      NotificationManager.warning('Cannot create domain')
    }
  }

  public async removeDomain() {
    const response = await deleteDomain(this.state.domainToRemove.value)
    if (response.status === 'Success') {
      this.getDomains()
      NotificationManager.success('Domain removed')
      this.setState({ isRemoveDomainModalOpen: !this.state.isRemoveDomainModalOpen, domainToRemove: null })
    } else {
      NotificationManager.warning('Cannot remove domain')
    }
  }

  public async updateDomainName() {
    const previousDomainName = this.state.domainPreviousValue
    const newDomainName = this.state.domainInputValue

    const response = await updateDomain(previousDomainName, newDomainName)
    if (response.status === 'Success') {
      this.setState({
        domainInputElem: null,
        domainInputValue: '',
        domainPreviousValue: '',
      })
      this.getDomains()

      NotificationManager.success('Updated domain name')
    } else {
      NotificationManager.warning('Cannot update domain name')
    }
  }

  public enableInputDomain(event: any) {
    const elem = event.target
    const domainInputId = elem.parentElement.getAttribute('domain-input')
    const inputElem: any = document.getElementById(domainInputId)

    if (inputElem != null) {
      this.setState({
        domainInputElem: inputElem,
        domainInputValue: inputElem.value,
        domainPreviousValue: inputElem.value,
      })
    } else {
      this.setState({
        domainInputElem: null,
        domainInputValue: '',
        domainPreviousValue: '',
      })
    }
  }

  private handleChangePlan(event: any) {
    const elem = event.target
    const value = elem.value
    this.setState({ planToDelete: value })
  }

  private handleChangePreferences(event: any) {
    const elem = event.target
    const key = event.target.id
    const value = elem.value
    if (key === 'input-symbols') {
      this.localStorageService.setSymbolsType(value)
      this.setState({ preferenceSymbol: value })
      NotificationManager.success('Updated parameter')
    } else if (key === 'input-measurementUnit') {
      this.localStorageService.setMeasurementUnit(value)
      this.setState({ preferenceMeasurementUnit: value })
      NotificationManager.success('Updated parameter')
    } else if (key === 'input-coordinatesFormat') {
      this.localStorageService.setCoordinatesFormat(value)
      this.setState({ preferenceCoordinatesFormat: value })
      NotificationManager.success('Updated parameter')
    } else {
      NotificationManager.warning('Unknown parameter')
    }
  }

  public buildNewTokenModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isNewTokenModalOpen}
        toggle={this.toogleNewTokenModal}
      >
        <ModalHeader toggle={this.toogleNewTokenModal}> Generated API key </ModalHeader>
        <ModalBody>
          {this.state.tokenGenerated}
          <CopyToClipboard text={this.state.tokenGenerated} onCopy={() => this.onNewTokenCopy()}>
            <i className="fas fa-copy fa-lg" />
          </CopyToClipboard>
        </ModalBody>
      </Modal>
    )
  }

  public buildNewApiKeyModal() {
    return (
      <Modal
        id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'}
        isOpen={this.state.isNewApiKeyModalOpen}
        toggle={this.toogleNewApiKeyModal}
      >
        <ModalHeader toggle={this.toogleNewApiKeyModal}> Generated API key </ModalHeader>
        <ModalBody>
          {this.state.tokenGenerated}
          <CopyToClipboard text={this.state.tokenGenerated} onCopy={() => this.onNewApiKeyCopy()}>
            <i className="fas fa-copy fa-lg" />
          </CopyToClipboard>
        </ModalBody>
      </Modal>
    )
  }

  public buildRemoveTokenModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemoveTokenModalOpen}>
        <ModalHeader toggle={() => this.toogleRemoveModalModal('')}> Remove API key </ModalHeader>
        <ModalBody>
          <div> The API key will be removed. Do you want to continue?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.removeToken()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public buildRemoveApiKeyModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemoveApiKeyModalOpen}>
        <ModalHeader toggle={() => this.toogleRemoveApiKeyModal('')}> Remove Campaing API key</ModalHeader>
        <ModalBody>
          <div> The API key will be removed. Do you want to continue?</div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.removeToken()}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  public buildRemovePlanModal() {
    return (
      <Modal id={this.props.isDarkMode ? 'modal-darkmode' : 'modal-light'} isOpen={this.state.isRemovePlanModalOpen}>
        <ModalHeader toggle={() => this.toogleRemovePlanModal()}> Remove Plan </ModalHeader>
        <ModalBody>
          <div>
            The Plan '<b>{this.state.planToDelete}</b>' will be removed. <br />
            If the plan is assigned to a vehicle, it will be replaced by 'idle' plan. <br />
            Do you want to continue?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.removePlan()}>
            Remove
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  private renderPreferencesEditor() {
    return (
      <div className="preferences-container">
        <h4>Local Preferences</h4>

        <Table
          id="preferences-table"
          responsive={true}
          striped={true}
          className={this.props.isDarkMode ? 'table-darkmode' : ''}
        >
          <tbody>
            {this.buildSymbolPreference()}
            {this.buildMeasurementUnitPreference()}
            {this.buildCoordinatesFormatPreference()}
          </tbody>
        </Table>
      </div>
    )
  }

  private buildSymbolPreference() {
    return (
      <tr>
        <th>Symbols</th>
        <th className="input-preferences">
          <select
            id="input-symbols"
            value={this.state.preferenceSymbol}
            onChange={this.handleChangePreferences}
            onClick={(event) => event.stopPropagation()}
          >
            <option value="normal" onClick={(event) => event.stopPropagation()}>
              Normal
            </option>
            <option value="military" onClick={(event) => event.stopPropagation()}>
              Military
            </option>
          </select>
        </th>
      </tr>
    )
  }

  private buildMeasurementUnitPreference() {
    return (
      <tr>
        <th>Measurement unit</th>
        <th className="input-preferences">
          <select
            id="input-measurementUnit"
            value={this.state.preferenceMeasurementUnit}
            onChange={this.handleChangePreferences}
            onClick={(event) => event.stopPropagation()}
          >
            <option value="metric" onClick={(event) => event.stopPropagation()}>
              Metric
            </option>
            <option value="nautic" onClick={(event) => event.stopPropagation()}>
              Nautic
            </option>
          </select>
        </th>
      </tr>
    )
  }

  private buildCoordinatesFormatPreference() {
    return (
      <tr>
        <th>Lat/Lon display format</th>
        <th className="input-preferences">
          <select
            id="input-coordinatesFormat"
            value={this.state.preferenceCoordinatesFormat}
            onChange={this.handleChangePreferences}
            onClick={(event) => event.stopPropagation()}
          >
            <option value="DD" onClick={(event) => event.stopPropagation()}>
              Decimal Degrees
            </option>
            <option value="DDM" onClick={(event) => event.stopPropagation()}>
              Degrees Decimal Minutes
            </option>
            <option value="DMS" onClick={(event) => event.stopPropagation()}>
              Degrees Minutes Seconds
            </option>
          </select>
        </th>
      </tr>
    )
  }

  private redirectToChatPage(chatName: string) {
    localStorage.setItem('chat-name', chatName)
    const chatLink = document.getElementById('chat-link')
    if (chatLink !== null) {
      chatLink.click()
    }
  }

  public toogleRemoveDomainModal(event: any | null) {
    if (event != null) {
      const elem = event.target
      const domainInputId = elem.parentElement.getAttribute('domain-input')
      const inputElem: any = document.getElementById(domainInputId)
      this.setState({ isRemoveDomainModalOpen: !this.state.isRemoveDomainModalOpen, domainToRemove: inputElem })
    } else {
      this.setState({ isRemoveDomainModalOpen: !this.state.isRemoveDomainModalOpen, domainToRemove: null })
    }
  }

  public toogleNewTokenModal() {
    this.setState({
      isNewTokenModalOpen: !this.state.isNewTokenModalOpen,
      tokenGenerated: '',
    })
  }

  public toogleNewApiKeyModal() {
    this.setState({ isNewApiKeyModalOpen: !this.state.isNewApiKeyModalOpen, tokenGenerated: '' })
  }

  public toogleRemoveModalModal(token: string) {
    this.setState({
      isRemoveTokenModalOpen: !this.state.isRemoveTokenModalOpen,
      tokenToRemove: token,
    })
  }

  public toogleRemoveApiKeyModal(token: string) {
    this.setState({
      isRemoveApiKeyModalOpen: !this.state.isRemoveApiKeyModalOpen,
      tokenToRemove: token,
    })
  }

  public toogleRemovePlanModal() {
    this.setState({ isRemovePlanModalOpen: !this.state.isRemovePlanModalOpen })
  }

  public onApiKeyCopy() {
    NotificationManager.success('API key copied to clipboard!')
  }

  public onNewTokenCopy() {
    NotificationManager.success('API key copied to clipboard!')
    this.toogleNewTokenModal()
  }

  public onNewApiKeyCopy() {
    NotificationManager.success('API key copied to clipboard!')
    this.toogleNewApiKeyModal()
  }

  public async generateToken() {
    const domains: any = document.getElementsByClassName('new-token-domain')
    const domainSelected: any = []
    for (const domain of domains) {
      if (domain.checked) domainSelected.push(domain.value)
    }

    const permissions: any = document.getElementsByClassName('new-token-permission')
    const permissionSelected: any = []
    for (const permission of permissions) {
      if (permission.checked) permissionSelected.push(permission.value)
    }

    const emailSelected: string = this.state.tokenEmailInput.trim()

    if (domainSelected.length === 0 || permissionSelected.length === 0 || emailSelected.length === 0) {
      NotificationManager.warning('Fields cannot be empty')
    } else {
      // GERAR TOKEN
      const resp = await createApiKey(emailSelected, domainSelected, permissionSelected)
      if (resp.status === 'Success') {
        const respMessage = resp.message.substring(0, resp.message.indexOf(':'))
        const respToken = resp.message.substring(resp.message.indexOf(':') + 1)

        this.setState({
          isNewTokenVisible: !this.state.isNewTokenVisible,
          tokenGenerated: respToken,
          isNewTokenModalOpen: !this.state.isNewTokenModalOpen,
          tokenEmailInput: '',
        })
        NotificationManager.success(respMessage)
        this.fetchApiKeys()
      } else {
        NotificationManager.warning(resp.message)
      }
    }
  }

  public async generateApiKey() {
    const domains: any = document.getElementsByClassName('new-token-domain')
    const domainSelected: any = []
    for (const domain of domains) {
      if (domain.checked) domainSelected.push(domain.value)
    }

    const permissions: any = document.getElementsByClassName('new-token-permission')
    const permissionSelected: any = []
    for (const permission of permissions) {
      if (permission.checked) permissionSelected.push(permission.value)
    }

    const emailSelected: string = this.state.tokenEmailInput.trim()

    if (domainSelected.length === 0 || permissionSelected.length === 0 || emailSelected.length === 0) {
      NotificationManager.warning('Fields cannot be empty')
    } else {
      const resp = await createApiKey(emailSelected, domainSelected, permissionSelected)
      if (resp.status === 'Success') {
        const respMessage = resp.message.substring(0, resp.message.indexOf(':'))
        const respToken = resp.message.substring(resp.message.indexOf(':') + 1)

        this.setState({
          isNewApiKeyVisible: !this.state.isNewApiKeyVisible,
          tokenGenerated: respToken,
          isNewApiKeyModalOpen: !this.state.isNewApiKeyModalOpen,
          tokenEmailInput: '',
        })
        NotificationManager.success(respMessage)
        this.fetchApiKeys()
      } else {
        NotificationManager.warning(resp.message)
      }
    }
  }

  public async removeToken() {
    const resp = await removeApiKey(this.state.tokenToRemove)
    if (resp.status === 'Success') {
      this.setState({
        isRemoveTokenModalOpen: false,
        isRemoveApiKeyModalOpen: false,
        isNewApiKeyVisible: false,
        tokenToRemove: '',
      })
      NotificationManager.success(resp.message)
      this.fetchApiKeys()
    } else {
      NotificationManager.warning(resp.message)
    }
  }

  public async removePlan() {
    const resp = await this.soiService.removePlan(this.state.planToDelete)
    if (resp.status === 'success') {
      this.setState({
        isRemovePlanModalOpen: !this.state.isRemovePlanModalOpen,
        planToDelete: '',
      })
      NotificationManager.success(resp.message)
      this.getPlansID()
    } else {
      NotificationManager.warning(resp.message)
    }
  }

  private buildUserProfilePage() {
    return (
      <>
        <i title="User Profile" className="fas fa-user fa-lg" onClick={this.redirectToUserProfilePage} />
        <Link id="user-link" to="/user/profile" />
      </>
    )
  }

  private redirectToUserProfilePage() {
    localStorage.setItem('user-profile', this.props.auth.currentUser.email)
    const userLink = document.getElementById('user-link')
    if (userLink !== null) {
      userLink.click()
    }
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    auth: state.auth,
    isDarkMode: state.isDarkMode,
    servicesAvailable: state.servicesAvailable,
    campaignSelected: state.campaignSelected,
  }
}

const actionCreators = {
  setUser,
  setCampaign,
  setServicesAvailable,
}

export default connect(mapStateToProps, actionCreators)(SettingsPanel)
